import React, { useState } from "react"
import styled from "@emotion/styled"
import FlexBox from "./global/FlexBox"
import theme, { mediaQueries } from "./theme"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { css } from "@emotion/react"
import icon from "../images/hf-logo-navbar.png"

const Wrapper = styled.header`
  position: fixed;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  right: 0;
  display: grid;
  grid-template-rows: 80% 20%;
  align-items: center;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  width: 100%;
  transform: ${(props) =>
    props.isClicked ? "translate3d(0,0,0)" : "translate3d(100vw, 0, 0)"};
  background-color: #1d281b;
  ${theme.mediaQueries.medium} {
    right: 0;
    left: initial;
  }
`

const StyledLink = styled.a`
  font-weight: 400;
  font-size: var(--h4);
  color: #efe6d5;
  font-family: var(--headingFont);
  ${mediaQueries.medium} {
    font-size: var(--h5);
  }
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
`

export default function Navbar() {
  const [isClicked, setClick] = useState(false)

  const iconStyles = css`
    color: white;

    width: 35px;
    height: 35px;
    margin-bottom: 1rem;
  `
  return (
    <React.Fragment>
      <div className="fixed z-10 top-0 w-full">
        <div className="flex justify-end py-4 px-8">
          <button
            style={{ background: "none" }}
            className="border-none w-[80px] h-[80px] p-0 top-0 right-0 z-[3] hover:cursor-pointer"
            onClick={() => setClick(!isClicked)}
          >
            {/*<svg className="w-full h-full fill-white" viewBox="0 0 24 24">*/}
            {/*  <path d="M12 2A3 3 0 0 0 9 5A3 3 0 0 0 11 7.83V9H8V11H11V19.92C10.26 19.79 9.5 19.58 8.79 19.27C8.05 18.95 7.4 18.56 6.82 18.09C6.24 17.62 5.78 17.11 5.44 16.55L7 15L3 12V15C3 15.97 3.27 16.88 3.82 17.72C4.37 18.56 5.09 19.31 6 19.95C6.87 20.59 7.84 21.09 8.88 21.45C9.93 21.81 10.97 22 12 22C13.03 22 14.07 21.8 15.12 21.44C16.16 21.08 17.13 20.58 18 19.95C18.92 19.31 19.63 18.57 20.18 17.72C20.73 16.88 21 15.97 21 15V12L17 15L18.56 16.55C18.22 17.11 17.76 17.62 17.18 18.09C16.6 18.56 15.95 18.95 15.21 19.27C14.5 19.58 13.74 19.79 13 19.92V11H16V9H13V7.82A3 3 0 0 0 15 5A3 3 0 0 0 12 2M12 4A1 1 0 0 1 13 5A1 1 0 0 1 12 6A1 1 0 0 1 11 5A1 1 0 0 1 12 4Z" />*/}
            {/*</svg>*/}
            <img style={{
              filter: "drop-shadow(0px 0px 0.75rem #000000)"
            }} alt="hold fast barbering logo" src={icon}/>
          </button>
        </div>
      </div>
      <Wrapper className={"wrapper"} isClicked={isClicked}>
        <div>
          <FlexBox
            textAlign="center"
            width="initial"
            px={[1]}
            variant="colCenter"
          >
            <StyledLink
              onClick={() => {
                setClick(!isClicked)
              }}
              href="#about"
            >
              ABOUT
            </StyledLink>
            <StyledLink
              onClick={() => {
                setClick(!isClicked)
              }}
              href="#merch"
            >
              Merch
            </StyledLink>
            <StyledLink
              onClick={() => {
                setClick(!isClicked)
              }}
              href="#brands"
            >
              Brands
            </StyledLink>
            <StyledLink
              onClick={() => {
                setClick(!isClicked)
              }}
              href="#appointment"
            >
              Appointment
            </StyledLink>
          </FlexBox>
        </div>
        <FlexBox
          textAlign="center"
          width="initial"
          px={[1]}
          variant="colCenter"
          alignItems="center"
        >
          <a href="https://www.instagram.com/hold_fast_barbering/">
            <FaFacebookF css={iconStyles} />
          </a>
          <a href="https://www.facebook.com/HoldFastBarbering">
            <FaInstagram css={iconStyles} />
          </a>
        </FlexBox>
      </Wrapper>
    </React.Fragment>
  )
}
