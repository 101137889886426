import React from "react"
import { css } from "@emotion/react"
import { mediaQueries } from "./theme"

const imageOne = css`
  grid-row: 2/3;
  ${mediaQueries.medium} {
    grid-row: initial;
    width: 100%;
    grid-column: 7/13;
  }
  ${mediaQueries.large} {
    grid-column: 8/13;
  }
  ${mediaQueries.extra_large} {
    grid-column: 8/12;
  }
`

export const imageTwo = css`
  grid-row: 4/5;
  ${mediaQueries.medium} {
    width: 100%;
    grid-column: 1/6;
    grid-row: 2/3;
  }
  ${mediaQueries.large} {
    grid-column: 1/6;
    grid-row: 2/3;
  }
`

const ParallaxImage = ({ src, alt, imagePos, disabled }) => {
  return (
    <div css={imagePos === 1 ? imageOne : imageTwo}>
      <img
        css={css`
          max-width: 400px;
          width: 100%;
          ${mediaQueries.medium} {
            max-width: 100%;
            width: 100%;
          }
        `}
        src={src}
        alt={alt}
      />
    </div>
  )
}

export default ParallaxImage
