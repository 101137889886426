import React from "react"
import Section from "../Section"
import theme from "../theme"
import {css} from "@emotion/react"

export const SharpAction = () => {
  return (
    <Section
      height="initial"
      bg={theme.colour.primary}
      sect_id="sharp_action"
      textColor="white"
      innerGridCss={css`
        padding: 4rem 2rem !important;
      `}
    >
      <div
        css={css`
          width: 100%;
          grid-column: 2/12;
          text-align: center;
        `}
      >
        <img
          style={{display: "block"}}
          src="https://res.cloudinary.com/seamer-design/image/upload/v1657107968/holdfastbarbering/sharp-is-the-action_utacz1.png"
          alt="Sharp is the action"
        />
      </div>
    </Section>
  )
}
