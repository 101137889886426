import { css } from "@emotion/react"
import { mediaQueries } from "../theme"

export const titleStyling = css`
  font-size: var(--h1);
  ${mediaQueries.medium} {
    grid-column: 1/7;
  }
`
export const paraStyling = css`
  grid-row: 3/4;
  ${mediaQueries.medium} {
    grid-column: 8/13;
    grid-row: 2/3;
    ${"" /* align-self: end; */}
  }
`
export const imgOneStyling = css`
  grid-column: 9/13;
  width: 100%;
`
