import * as React from "react"
import Layout from "../components/layout"
import About from "../components/sections/About"
import Hero from "../components/sections/Hero"
import ImageBlock from "../components/sections/ImageBlock"
import Merchandise from "../components/sections/Merchandice"
import Brands from "../components/sections/Brands"
import Appointment from "../components/sections/Appointment"
import { useEffect } from "react"
import { SharpAction } from "../components/sections/SharpAction"
import InstaImage from "../components/InstaImage"
import SecondImage from "../images/image-blocks/second-image-blowdryer.jpg"
import { SEO } from "../components/seo"
const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <ImageBlock
        image="https://res.cloudinary.com/seamer-design/image/upload/f_auto,q_auto/v1655811847/holdfastbarbering/haircut-opt_fusghy.jpg"
        alt=""
      />
      <SharpAction />
      <ImageBlock image={SecondImage} alt="Cutting hair with blowdryer" />
      <Merchandise />
      <Brands />
      <Appointment />
      <InstaImage />
    </Layout>
  )
}

export default IndexPage

export const Head = () => <SEO />
