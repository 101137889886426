import React from "react"
import { Global } from "@emotion/react"
import { ThemeProvider } from "@emotion/react"
import theme, { breakpoints } from "./theme"

//Styles
import "@fontsource/eb-garamond/400.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/300.css"
import "../styles/globalVariables.css"
import { globalStyles } from "../styles/globalStyles"

//Components
import Navbar from "./Navbar"
import Footer from "./Footer"
import { SideNavbar } from "./SideNavbar"
import { useMediaQuery } from "react-responsive"
import useWindowDimensions from "./hooks/WindowDimensionHook"

export default function Layout({ children }) {
  const { width } = useWindowDimensions()
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        {width > 1024 ? <SideNavbar /> : <Navbar />}

        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  )
}
