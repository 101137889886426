export const space = [0, "2rem", "4rem", "8rem"]
export const sizes = [0, 1024, 1280, 1680]
export const breakpoints = ["40em", "52em", "64em", "80em"]
breakpoints["sm"] = "40em"
breakpoints["md"] = "52em"
breakpoints["lg"] = "64em"
breakpoints["xl"] = "80em"
export const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
  extra_large: `@media screen and (min-width: ${breakpoints[3]})`,
  small__max: `@media screen and (max-width: ${breakpoints[0]})`,
  medium__max: `@media screen and (max-width: ${breakpoints[1]})`,
}

const green = "#4A694C"
const gold = "gold"
export const colour = {
  primary: green,
  seconday: gold,
}

const theme = {
  sizes,
  space,
  colour,
  breakpoints,
  mediaQueries,
}

export default theme
