import styled from "@emotion/styled";
import shouldForwardProp from "@styled-system/should-forward-prop";
import {
  grid,
  typography,
  flexbox,
  space,
  layout,
  variant,
  color,
} from "styled-system";

const FlexBox = styled("div", { shouldForwardProp })(
  {
    display: "flex",
    zIndex: "3",
    backgroundColor: "none",
  },
  flexbox,
  color,
  grid,
  space,
  typography,
  layout,
  variant({
    variants: {
      col: {
        flexDirection: "column",
      },
      colCenter: {
        flexDirection: "column",
        justifyContent: "center",
      },
      row: {
        flexDirection: "row",
      },
      rowApart: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
  })
);

FlexBox.defaultProps = {
  mx: "auto",
  width: "90%",
};

export default FlexBox;
