import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import theme from "../theme"
import backgroundImage from "../../images/hero/hold-fast-lounge.jpg"

export default function Hero() {
  return (
    <div className="h-[100vh] grid justify-center items-center overflow-hidden grid-cols-1">
      <img
        className="h-[inherit] object-cover w-full col-start-1 col-end-1 row-start-1 row-end-1"
        src={backgroundImage}
        alt="Hold Fast Barbering chair"
        placholder="dominantColor"
        loading="eager"
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          height: "100vh",
          maxHeight: "700px",
        }}
        imgStyle={{
          padding: `${theme.space[1]} 10%`,
        }}
        objectFit="contain"
        loading="eager"
        placeholder="blurred"
        src="../../images/hero/anchor-white.png"
        alt="Hold Fast Barbering Logo"
      />
    </div>
  )
}
