import React from "react"
import { ParallaxBanner } from "react-scroll-parallax"
import MediaQuery from "react-responsive"
import { css } from "@emotion/react"
import { breakpoints } from "../../theme"
import { ParallaxProvider } from "react-scroll-parallax"

export default function ImageBlock({ image, alt }) {
  return (
    <ParallaxProvider>
      <MediaQuery minWidth={breakpoints.lg}>
        <ParallaxBanner
          layers={[
            {
              image: image,
              speed: -50,
            },
          ]}
          style={{ height: "85vh" }}
        />
      </MediaQuery>
      <MediaQuery maxWidth={breakpoints.lg}>
        <div
          css={css`
            height: 100%;
          `}
        >
          <img
            css={css`
              height: 100%;
              width: 100%;
              object-fit: cover;
              display: block;
              max-height: 800px;
            `}
            alt={alt}
            src={image}
          />
        </div>
      </MediaQuery>
    </ParallaxProvider>
  )
}
