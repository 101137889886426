import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { sizes } from "../theme"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, FreeMode } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "./styles.css"
import useWindowDimensions from "../hooks/WindowDimensionHook"

const Wrapper = styled.div`
  mix-blend-mode: multiply;
  overflow: hidden;
  //height: 75vh;
  color: ${(props) => (props.color ? props.color : "white")};
  display: grid;
  // min-height: ${(props) => (props.height ? props.height : "75vh")};
  position: relative;
`

const BGColor = styled.div`
  grid-area: 1/1;
  mix-blend-mode: multiply;
  background-color: ${(props) => props.bg};
  width: 100%;
  z-index: 1;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow-y: auto;
`
const InstaImage = () => {
  const [instaImages, setInstaImages] = useState([])
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "instagram" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)

  // const scrappedImageData = useStaticQuery(graphql`
  //   query {
  //     allInstaNode {
  //       edges {
  //         node {
  //           localFile {
  //             childImageSharp {
  //               gatsbyImageData
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  let mappedInstaImages =
    !instaImages.error &&
    instaImages?.map((image) => {
      return {
        url: image.thumbnail_src,
      }
    })
  console.debug(mappedInstaImages)
  let images = data?.allFile?.edges?.map(({ node }) =>
    getImage(node?.childImageSharp)
  )

  useEffect(() => {
    async function getDate() {
      try {
        const res = await fetch("/api/instagram")
        setInstaImages(await res.json())
      } catch (e) {
        console.debug(e)
      }
    }
    getDate()
  }, [])
  const { width } = useWindowDimensions()

  return (
    <Wrapper>
      <BGColor bg="#4A694C" />
      <StaticImage
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
          grid-area: 1/1;
          position: absolute;
          top: 0;
        `}
        src="../images/image-blocks/paper-used-opt.jpg"
        alt="used paper background"
      />
      <div
        css={css`
          padding: 4rem 2rem;
          width: 100%;
          max-width: calc(100vw);
          margin: 0 auto;
          grid-area: 1/1;
          z-index: 1;
          /* @media screen and (min-width: 640px) {
            max-width: calc(100vw - 2rem);
          } */
          @media screen and (min-width: 1680px) {
            max-width: ${sizes[3]}px;
          }
        `}
      >
        <h3
          css={css`
            text-align: center;
          `}
        >
          Follow us on instagram
        </h3>
        <div className="swiper-container">
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView="auto"
            loop
            spaceBetween={30}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className=""
          >
            {images.map((image, index) => (
              <SwiperSlide>
                <a
                  href="https://www.instagram.com/hold_fast_barbering/"
                  target="_blank"
                  className="h-full w-full"
                  rel="noreferrer"
                  key={index}
                >
                  <GatsbyImage
                    style={{ width: "100%", height: "100%" }}
                    objectFit="cover"
                    image={image}
                    alt=""
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Wrapper>
  )
}

export default InstaImage
