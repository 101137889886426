import React from "react"
import Section from "../Section"
import styled from "@emotion/styled"
import { mediaQueries } from "../theme"
import Form from "../Form"
import { css } from "@emotion/react"

const LeftContent = styled.div`
  ${mediaQueries.medium} {
    grid-column: 2/7;
    grid-row: 2/3;
  }
  a {
    display: block;
  }
`

const imgStyles = css`
  max-width: 500px;
  width: 100%;
  ${mediaQueries.medium} {
    max-width: 100%;
    grid-column: 7/13;
    grid-row: 1/2;
  }
`

const RightContent = css`
  ${mediaQueries.medium} {
    grid-column: 8/13;
    grid-row: 2/3;
    align-self: end;
  }
`

export default function Appointment() {
  return (
    <Section
      innerGridCss={css`
        align-items: start;
        justify-content: start;
      `}
      bg="none"
      textColor="black"
      sect_id="appointment"
      height="60vh"
    >
      <img
        src="https://res.cloudinary.com/seamer-design/image/upload/v1646017138/holdfastbarbering/comb_2x_fye7jy.png"
        css={imgStyles}
        alt="Comb"
      />
      <LeftContent>
        <h2>
          Book an <br />
          Appointment
        </h2>
        <p>
          To book an appointment please contact us via phone or email.
          <br />
          Alternatively, you can leave your details opposite and we will be in
          contact ASAP.
        </p>
        <p>
          Phone:{" "}
          <a style={{ display: "inline" }} href="tel:0435 293 114">
            0435 293 114
          </a>
          <br />
          Email:{" "}
          <a
            style={{ display: "inline" }}
            href="mailto:bookings@holdfastbarbering.com.au"
          >
            bookings@holdfastbarbering.com.au
          </a>
        </p>
      </LeftContent>
      <div css={RightContent}>
        <Form />
      </div>
    </Section>
  )
}
