import React from "react"
import Section from "../Section"
import { colour, mediaQueries } from "../theme"
import { titleStyling, paraStyling } from "./globalSectionStyling"
import ParallaxImage from "../ParallaxImage"
import { css } from "@emotion/react"

const secondImageStyling = css`
  ${
    "" /* ${mediaQueries.large} {
    margin-bottom: 4rem;
  } */
  }
`
const aboutHeaderStyling = css`
  ${mediaQueries.large} {
    grid-column: 1/7;
  }
`

export default function About() {
  return (
    <Section bg={colour.primary} sect_id="about">
      <h2 css={[titleStyling, aboutHeaderStyling]}>
        Exclusive, <br />
        on demand <br /> Barbering Services{" "}
      </h2>
      <ParallaxImage
        src="https://res.cloudinary.com/seamer-design/image/upload/v1645744261/holdfastbarbering/Shaving_razor_3x_mu29ad.png"
        alt="hello"
        imagePos={1}
      />
      <ParallaxImage
        src="https://res.cloudinary.com/seamer-design/image/upload/v1645747135/holdfastbarbering/safety-razor_3x_gaf0tp.png"
        alt="razor"
        imagePos={2}
      />
      <p css={[paraStyling, secondImageStyling]}>
        Set in a private home studio setting, Hold Fast Barbering caters to the
        discerning gentlemen of Ballarat for all their barbering service needs.
        Born from tradition, the exclusive and intimate nature of our cuts
        allows for a tailored experience for all that sit in the chair.
      </p>
    </Section>
  )
}
