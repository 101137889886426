import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import FlexBox from "../global/FlexBox"
import { mediaQueries } from "../theme"

const localColor = css`
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`

const StyledLink = styled.a`
  font-weight: 400;
  font-size: var(--h4);
  color: black;
  font-family: var(--headingFont);
  ${mediaQueries.medium} {
    font-size: var(--h5);
  }
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
  ${localColor}
`
const Navbar = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 999;
  //backdrop-filter: blur(10px);
`

const NavbarInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 800px;
`

const iconStyles = css`
  width: 35px;
  height: 35px;
  margin-bottom: 1rem;
`

export const SideNavbar = () => {
  const [isClicked, setClick] = useState(false)
  return (
    <Navbar>
      <NavbarInner>
        <StaticImage
          src="../../images/anchor-white-navbar.png"
          style={{ maxWidth: "150px", alignSelf: "center" }}
          alt="Hold Fast Barbering"
          loading="eager"
          placeholder="blurred"
          quality={100}
        />
        <FlexBox
          textAlign="center"
          width="initial"
          px={[1]}
          variant="colCenter"
        >
          <StyledLink
            onClick={() => {
              setClick(!isClicked)
            }}
            href="#about"
          >
            ABOUT
          </StyledLink>
          <StyledLink
            onClick={() => {
              setClick(!isClicked)
            }}
            href="#merch"
          >
            Merch
          </StyledLink>
          <StyledLink
            onClick={() => {
              setClick(!isClicked)
            }}
            href="#brands"
          >
            Brands
          </StyledLink>
          <StyledLink
            onClick={() => {
              setClick(!isClicked)
            }}
            href="#appointment"
          >
            Appointment
          </StyledLink>
        </FlexBox>
        <FlexBox
          textAlign="center"
          width="initial"
          px={[1]}
          variant="colCenter"
          alignItems="center"
        >
          <a href="https://www.instagram.com/hold_fast_barbering/">
            <FaFacebookF css={[iconStyles, localColor]} />
          </a>
          <a href="https://www.facebook.com/HoldFastBarbering">
            <FaInstagram css={[iconStyles, localColor]} />
          </a>
        </FlexBox>
      </NavbarInner>
    </Navbar>
  )
}
