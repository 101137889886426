import React from "react"
import Section from "../Section"
import { paraStyling } from "./globalSectionStyling"
import ParallaxImage from "../ParallaxImage"
import { css } from "@emotion/react"
import { mediaQueries } from "../theme"
import secondImage from "../../images/merch/second-merh-forest.jpg"

const titleStyling = css`
  ${mediaQueries.medium} {
    grid-column: 1/6;
  }
  font-size: var(--h1);
`

const jumperWrapper = css`
  ${mediaQueries.medium} {
    grid-column: 1/7;
  }
  ${mediaQueries.large} {
    grid-column: 1/8;
  }
`

const merchParaStyling = css`
  ${mediaQueries.large} {
    align-self: center;
    grid-column: 9/13;
  }
`

const Merchandise = () => {
  return (
    <Section textColor="black" sect_id="merch" bg="none">
      <h2 data-aos="fade-down" css={[titleStyling]}>
        Hold Fast <br /> Merchandise
      </h2>
      <ParallaxImage
        src="https://res.cloudinary.com/seamer-design/image/upload/q_auto:eco/v1645675466/holdfastbarbering/Brush_3x_rmbaoo.png"
        alt="beard brush"
        imagePos={1}
        data-aos="fade-down"
      />
      <div className="relative w-full" css={jumperWrapper}>
        <div className="flex max-w-full flex-col sm:flex-row">
          <img
            src="https://res.cloudinary.com/seamer-design/image/upload/c_scale,f_auto,q_auto:eco,w_1000/v1662880715/holdfastbarbering/merch_mike_db85oj.jpg"
            alt="Merchandice jumper "
            className="z-10 rounded-3xl  max-w-6xl w-full sm:w-1/2 h-auto object-cover mr-2"
          />
          <img
            className="rounded-3xl max-w-6xl w-full  sm:w-1/2 h-auto object-cover mt-4 sm:mt-0"
            src={secondImage}
            alt="Merchandice tshirt"
          />
        </div>
      </div>
      <p css={[paraStyling, merchParaStyling]}>
        With the Shears and Anchor being worn proudly by our valued clients, we
        offer a range of high quality tshirts and hoodies using only AS Colour
        garments in all sizes.
      </p>
    </Section>
  )
}

export default Merchandise
