import styled from "@emotion/styled";
import { variant } from "styled-system";
import { colour } from "../theme";

const Button = styled("button")(
  variant({
    variants: {
      primary: {
        color: "black",
        background: "none",
        border: "1px solid black",
        padding: "0.75rem 0",
        "&:hover": {
          cursor: "pointer",
          "&::before": {
            border: "1px solid black",
            transform: "translateX(20px)",
          },
        },
      },
      secondary: {
        color: "black",
        bg: "white",
      },
    },
  })
);

export default Button;
