import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";
import { sizes } from "../theme";

const Wrapper = styled.footer`
  background-color: #0d0f12;
`;

const Inner = styled.div`
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: space-between;
  max-width: ${sizes[3]}px;
  padding: 1rem 2rem;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Inner>
        <Link to="/privacy">Privacy</Link>
        <p>Copyright @ {new Date().getFullYear()} Hold Fast Barbering</p>
      </Inner>
    </Wrapper>
  );
};

export default Footer;
