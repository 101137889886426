import React from "react"
import Section from "../Section"
import theme, { mediaQueries, space } from "../theme"
import { css } from "@emotion/react"
import { titleStyling } from "./globalSectionStyling"
import { StaticImage } from "gatsby-plugin-image"

const logos = [
  {
    imgUrl:
      "https://res.cloudinary.com/seamer-design/image/upload/v1646785194/holdfastbarbering/logos/calvary-white-cropped__padding_uao7wi.png",
    alt: "Calvary Men's Products",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/seamer-design/image/upload/v1646020994/holdfastbarbering/logos/truefitt-and-hill_d8memx.png",
    alt: "True Fit & Hill",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/seamer-design/image/upload/v1646784674/holdfastbarbering/logos/head_r6sdjv.png",
    alt: "Captain Fawcett",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/seamer-design/image/upload/v1646784870/holdfastbarbering/logos/muehle_logo_lgp8sr.svg",
    alt: "Mule",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/seamer-design/image/upload/co_rgb:ffffff,e_negate,f_auto,q_auto/v1657016104/holdfastbarbering/logos/barber-brands_q1reyu.png",
    alt: "Barber Brands",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/seamer-design/image/upload/v1657016646/holdfastbarbering/logos/logo-home-big_kitmzx.png",
    alt: "Depot Men's Grooming Goods",
  },
]

const paraStyling = css`
  //grid-row: /3;
  ${mediaQueries.medium} {
    grid-column: 1/6;
  }
`
export default function Brands() {
  return (
    <Section sect_id="brands" bg={theme.colour.primary} height="30vh">
      <h2 css={titleStyling}>
        {/*Exclusive <br /> Men's Brands*/}
        Premium Men's <br /> Grooming Brands
      </h2>
      <div css={paraStyling}>
        <p>
          Hold Fast Barbering is proud to partner with Barber Brands
          International to offer the very best in mens product brands in order
          to truly enhance that grooming experience. From incredible hair
          products, varied range of beard care oils and balms to the most
          distinguished colognes, all of your essential styling needs are
          available to ensure our clients are the sharpest they can be.
        </p>
      </div>
      <div
        css={css`
          filter: invert(100%);
          display: grid;
          grid-row: 3/4;
          grid-template-columns: minmax(0, 250px);
          grid-auto-rows: minmax(0, 100px);
          gap: ${space[1]};
          place-items: center;
          ${mediaQueries.medium} {
            grid-column: 8/13;
            grid-row: 1/3;
          }
          @media (min-width: 1420px) {
            grid-template-columns: 1fr 1fr;
            gap: ${space[2]};
          }
        `}
      >
        {logos.map((item, index) => (
          <img
            key={index}
            loading="lazy"
            css={css`
              max-height: 100%;
            `}
            src={item.imgUrl}
            alt={item.alt}
          />
        ))}
      </div>
    </Section>
  )
}
