import { css } from "@emotion/react"
import { mediaQueries } from "../components/theme"

export const globalStyles = css`
  html {
    font-size: var(--fontSize);
    scroll-behavior: smooth;
  }
  html,
  body,
  #___gatsby,
  #gatsby-focues-wrapper {
    height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    position: relative;
    line-height: 1.75;
    font-weight: var(--fontNormal);
    font-family: var(--headingFont);
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  input,
  textarea,
  select,
  button {
    font-size: inherit;
    font-family: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1.38rem;
    line-height: 1.3;
    font-weight: var(--fontNormal);
    font-family: var(--headingFont);
    letter-spacing: -2px;
  }

  h1 {
    font-size: var(--h1);
    margin-top: 0;
  }

  h2 {
    font-size: var(--h2);
    line-height: 1.1em;
  }

  h3 {
    font-size: var(--h3);
  }

  p {
    margin-bottom: 1rem;
    font-size: 20px;
    ${mediaQueries.medium} {
      font-size: 24px;
    }
  }

  ol,
  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    color: inherit;
  }
`
