import React from "react"
import styled from "@emotion/styled"
import { mediaQueries, sizes, space } from "./theme"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  mix-blend-mode: multiply;
  overflow: hidden;
  color: ${(props) => (props.color ? props.color : "white")};
  display: grid;
  /* min-height: ${(props) => (props.height ? props.height : "75vh")}; */
  position: relative;
  grid-template-columns: 1fr;
`

const BGColor = styled.div`
  grid-area: 1/1;
  mix-blend-mode: multiply;
  background-color: ${(props) => props.bg};
  width: 100%;
  z-index: 1;
`
const InnerGrid = styled.div`
  display: grid;
  gap: ${space[2]} 0;
  grid-template-columns: 1fr;
  max-width: ${sizes[3]}px;
  grid-area: 1/1;
  width: 100%;
  z-index: 1;
  height: inherit;
  padding: ${space[2]} ${space[1]};
  ${mediaQueries.medium} {
    margin: 0 auto;
    gap: ${space[2]} 0;
    grid-template-columns: repeat(12, 1fr);
    padding: ${space[2]} ${space[1]};
    align-items: start;
  }
  @media screen and (min-width: 1024px) and (max-width: 1900px) {
    padding-right: 275px;
  }
  ${(props) => props.innerGridCss};
`
export default function Section(props) {
  const { children, sect_id, bg, textColor, innerGridCss, height } = props
  return (
    <Wrapper id={sect_id} color={textColor} height={height}>
      <BGColor bg={bg} />
      <StaticImage
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
          grid-area: 1/1;
          position: absolute;
          top: 0;
        `}
        loading="eager"
        src="../images/image-blocks/paper-used-opt.jpg"
        alt="used paper background"
      />
      <InnerGrid innerGridCss={innerGridCss}>{children}</InnerGrid>
    </Wrapper>
  )
}
