import React from "react"
import { useForm, ValidationError } from "@formspree/react"
import { css } from "@emotion/react"
import Button from "./global/Button"

const formStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const inputStyling = css`
  padding: 1rem;
  width: 100%;
  color: black;
  background: none;
  border: 1px solid black;
  border-radius: 0;
  margin-bottom: 0.5rem;
`

const labelStyling = css`
  flex: 1;
`

export default function Form() {
  const [state, handleSubmit] = useForm("mbjqwjol")
  if (state.succeeded) {
    return <p>Thank you for inquiring! We'll be in touch soon.</p>
  }
  return (
    <form css={formStyle} onSubmit={handleSubmit}>
      <label htmlFor="first-name">
        <input
          css={inputStyling}
          type="text"
          id="first-name"
          placeholder="Name"
        />
      </label>
      <div
        css={css`
          display: flex;
          gap: 1rem;
          align-items: stretch;
        `}
      >
        <label css={labelStyling} htmlFor="email">
          <input
            placeholder="Email"
            css={inputStyling}
            id="email"
            type="email"
            name="email"
          />
        </label>
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <label css={labelStyling} htmlFor="mobile">
          <input
            placeholder="Mobile"
            css={inputStyling}
            id="mobile"
            type="phone"
            name="mobile"
          />
        </label>
      </div>
      <textarea
        placeholder="Message"
        css={inputStyling}
        id="message"
        name="message"
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <Button variant="primary" type="submit" disabled={state.submitting}>
        Submit
      </Button>
    </form>
  )
}
